<template>
  <RedditoSenzaSorpreseComponent
    v-for="polizza in prodotto.polizze"
    :key="polizza.idrsspolizza"
    :prodotto="prodotto"
    :index="index"
    :polizza="polizza"
  />
</template>

<script>
import RedditoSenzaSorpreseComponent from "@/components/prodotti/RedditoSenzaSorprese";
export default {
  name: "RedditoSenzaSorpreseBrkComponent",
  //I am passing the name of the Component as a prop
  props: {
    prodotto: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
  },
  components: {
    RedditoSenzaSorpreseComponent,
  },
  setup() {
    return {};
  },
};
</script>
